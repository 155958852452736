@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300&family=Questrial&family=Source+Serif+Pro:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Assistant&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

img{
  width: 15em;
  border:0.2em solid lightpink;
  border-radius:30px;
}

.navigation{
  display:flex;
  justify-content:space-around;
  border-bottom: 0.1em solid black;
  padding:0.5em;
  
}

.navigation a{
  text-decoration:none;
  color:black;
  font-size: 1.25em;
}

.navigation a:hover{
  color:pink;
}

.contact-links{
  display:flex;
  flex-direction:column;
}

.intro{
font-family: "Manrope", sans-serif;
font-size: 4em;
}

.intro-container{
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
}

/* container for the links */
.routes{ 
  /* display:flex;
  justify-content: flex-end; */
}

.about-me{
  font-size: 1.5em;
  font-family: 'Assistant', sans-serif;
}

.description-image{
  display:flex;
  justify-content:space-around;
}

.description{
  width:60%;
  text-align:left;
}

.project{
  margin-top:10px;
  margin-bottom:10px;
  
}





